import React, { useEffect, useState } from "react";
import { MockupStore } from "../store/Mockup.store";
import mockupGeneratorService from "../../services/mockupGenerator.service";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { SendOutlined, SyncOutlined } from "@ant-design/icons";
import { Popover, Input } from "antd";

const Frontend = () => {
  const {
    projectPageLists,
    setProjectPageLists,
    activePageId,
    setRequirementApiStatus,
    requirementApiStatus,
    modalState,
    setModalState,
    
  } = MockupStore((state: any) => state);
  const { projectId, threadId, framework } = useParams();
  const [isPopoverEnaballed, setIsPopoverEnaballed] = useState(false);
  const[userPromptValue,setUserPromptValue]=useState("");

  const generatePagewiseFeckendCode = async () => {
    setIsPopoverEnaballed(false);
    setRequirementApiStatus(true);
    let codeChunks = "";
    const payload = {
      project_id: projectId,
      page_id: activePageId,
      thread_id: threadId,
      //frontend_framework: framework==="0"?"React With Typescript":framework==="1"?"Vue js":"Next js",
      input_text: userPromptValue,
    };
    try {
      const response: any = await mockupGeneratorService.generateFeCode(
        payload
      );
      console.log("html", response.body);
      const reader1: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      while (true) {
        const result1: any = await reader1?.read();
        console.log(result1);
        if (result1) {
          const { done, value } = result1;
          if (done) {
            break;
          }
          codeChunks += new TextDecoder().decode(value);
          const stableCodeChunks = codeChunks;
          console.log(projectPageLists);
          const updatedPages = projectPageLists.saved_page_list.map(
            (page: any) => {
              if (page.page_id === activePageId) {
                return {
                  ...page,
                  frontEndCode: stableCodeChunks,
                };
              }
              return page;
            }
          );

          setProjectPageLists({
            ...projectPageLists,
            saved_page_list: updatedPages,
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequirementApiStatus(false);
      setUserPromptValue("");
    }
  };

  useEffect(() => {
    activePageId &&
      (projectPageLists?.saved_page_list?.find(
        (e: any) => e.page_id === activePageId
      )?.frontEndCode === null ||
        projectPageLists?.saved_page_list?.find(
          (e: any) => e.page_id === activePageId
        )?.frontEndCode === undefined) &&
      generatePagewiseFeckendCode();
  }, [activePageId]);
  console.log(projectPageLists.saved_page_list);
  return (
    <>
      <div className="htmlMockupButtonWrap">
        <Popover
          open={isPopoverEnaballed}
          trigger="click"
          placement="left"
          content={
            <Input
              placeholder="Write a prompt"
              value={userPromptValue}
              size="large"
              suffix={
                <SendOutlined
                  onClick={
                    userPromptValue ? generatePagewiseFeckendCode : undefined
                  }
                />
              }
              onChange={(e) => setUserPromptValue(e.target.value)}
            />
          }
        >
          <SyncOutlined
            spin={requirementApiStatus}
            onClick={() => setIsPopoverEnaballed(true)}
          />
        </Popover>
      </div>
      {projectPageLists?.saved_page_list?.map((el: any) =>
        el.page_id === activePageId ? (
          <div className="requirementContainer">
            <div className="htmlMockupButtonWrap"></div>
            <div className="content-textarea">
              <p>
                <ReactMarkdown>{el.frontEndCode}</ReactMarkdown>
              </p>
            </div>
          </div>
        ) : null
      )}
    </>
  );
};

export default Frontend;
