import {
  DeleteFilled,
  DownloadOutlined,
  EditOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  DrawerProps,
  Input,
  notification,
  Pagination,
  Space,
  Spin,
  Table,
  Tooltip,
  Upload,
  UploadProps,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useState } from "react";
import mockupGeneratorService from "../../services/mockupGenerator.service";
import { useNavigate } from "react-router-dom";
import { alignPropType } from "react-bootstrap/esm/types";
import { ColumnsType } from "antd/es/table";
import { MockupStore } from "../store/Mockup.store";
import JSZip from "jszip";
import saveAs from "file-saver";
import RightDrawer from "./RightDrawer";


const MockupGenerator = () => {
  const [resData, setResData] = useState([]);
  const [viewJsCodeModalOpen, setViewJsCodeModalOpen] = useState(false);
  const [viewCssCodeModalOpen, setViewCssCodeModalOpen] = useState(false);
  const [singleFileCode, setSingleFileCode] = useState("");
  const navigation = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageList, setPageList] = useState<any>(); 
  const [statusSvaePageList,setStatusSvaePageList]=useState(false);
  const[uploadFileName,setUploadFileName]=useState("");
  const {setProjectPageLists,setActivePageId}=MockupStore((state: any) => state);
  const[isDownloadPossible,setIsDownloadPossible]=useState(0);
  const [isRightDrawerOpen,setIsRightDrawerOpen] = useState<any>(false);
  const [rightDrawerPlacement, setRightDrawerPlacement] = useState<DrawerProps['placement']>('right');
  const [api, contextHolder] = notification.useNotification();

  const getProjectList = async () => {
    try {
      const response: any = await mockupGeneratorService.getProjectList();
      console.log(response);
      setResData(response.data.projects);
      setProjectPageLists([])
    } catch {}
  };

  const  handleFileUpload=async(file:File)=>
  {
    setUploadFileName(file.name)
    
    try {
      const payload = { file: file };
      const response: any = await mockupGeneratorService.generateSection(payload);
      if(response.data.project_id)
      {
        console.log(response.data)
        setProjectPageLists(response.data)
     
      navigation(`/MockupGenerator/requirement/${response.data.project_id}/${response.data.thread_id}`)
      }
    } catch (error) {
      console.log(error);
    }
    finally
    {
        setStatusSvaePageList(true)
        setUploadFileName("")
    }
  }

 



  const handleDelete = async (projectId: number) => {
    try {
      const payload = { project_id: projectId };
      const response: any = await mockupGeneratorService.deleteProject(payload);
      if (response.data.msg_id) {
        const afterDelete = resData.filter(
          (e: any) => e.project_id !== projectId
        );
        setResData(afterDelete);
      }
    } catch (error) {}
  };



  const handlePageChange = (page: any, pageSize: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleFileNameClick=(projectId:number,threadId:string)=>
  {
    navigation(`/MockupGenerator/requirement/${projectId}/${threadId}`)
  }
const handleGenerateFeCode=async(projectId:number,threadId:string)=>
{
  setIsDownloadPossible(projectId)
  const tempProjectDetailsArray:any= await getProjecDetails(projectId,threadId)
  if (tempProjectDetailsArray?.project?.all_js_code && tempProjectDetailsArray?.project?.all_css_code )
  {
    navigation(`/MockupGenerator/codegenerator/${projectId}/${threadId}`)
  }
  else{
    setIsDownloadPossible(-1)
  }
}

  const handleZipDownload=async(projectId:number,threadId:string)=>
  {
    setIsDownloadPossible(projectId)
   const tempProjectDetailsArray:any= await getProjecDetails(projectId,threadId)
   if (tempProjectDetailsArray?.project?.all_js_code && tempProjectDetailsArray?.project?.all_css_code )
   {
    setIsDownloadPossible(0)
   const zip = new JSZip();
   zip.file('script.js', tempProjectDetailsArray.project.all_js_code.replace("```", ''));
   zip.file('style.css',  tempProjectDetailsArray.project.all_css_code);
   
   tempProjectDetailsArray.saved_page_list.forEach((pageWiseEle:any, index:number) => {
         zip.file(`${pageWiseEle?.page_title.replace(/\s+/g, '-').toLowerCase()}.html`, pageWiseEle.html_code);
   });
   
   try {
     const zipBlob = await zip.generateAsync({ type: 'blob' });
     saveAs(zipBlob, 'files.zip');
   } catch (error) {
     console.error('Error generating ZIP file:', error);
   }
  }
  else
  {
    setIsDownloadPossible(-1)
  }
  

  }

  const getProjecDetails = async (projectId:number,threadId:string) => {
    console.log("hi...")
    try {
      const payload = {
        thread_id: threadId,
        project_id: projectId
      }
      const response: any = await mockupGeneratorService.getProjectDetails(payload);
      console.log("response", response.data)
      return  response.data
    }
    catch {

    }
  }


  const projectColumns: ColumnsType<any> = [
    {
      title: "File Name",
      width: "70%",
      dataIndex: "project_name",
      render: (text: string, record: any) => (
        <div onClick={() => handleFileNameClick(record.project_id,record.thread_id)} style={{ cursor: 'pointer'}}>
          {text}
        </div>
      ),
    },
    {
      title: "Uploaded On",

      render: (record: any) => <div>{record.created_on.split("T")[0]}</div>,
    },
    {
      title: "Color Scheme",
      dataIndex: "project_color_scheme",
      render: (colorScheme: string) => {
        try {
          const colors = JSON.parse(colorScheme);
          return (
            <div className="colorSchemeWrapper">
              <Tooltip title="Primary Color">
                <div
                  className="colorBox primaryColor"
                  style={{ backgroundColor: colors.primary_color }}
                />
              </Tooltip>
              <Tooltip title="Secondary Color">
                <div
                  className="colorBox secondaryColor"
                  style={{ backgroundColor: colors.secondary_color }}
                />
              </Tooltip>
              <Tooltip title="Text Color">
                <div
                  className="colorBox textColor"
                  style={{ backgroundColor: colors.text_color }}
                />
              </Tooltip>
              <Tooltip title="Table Header Color">
                <div
                  className="colorBox tableHeaderColor"
                  style={{ backgroundColor: colors.table_header_color }}
                />
              </Tooltip>
              <Tooltip title="Table Header Text Color">
                <div
                  className="colorBox tableHeaderTextColor"
                  style={{ backgroundColor: colors.table_header_text_color }}
                />
              </Tooltip>
            </div>
          );
        } catch (error) {
          return <div>Error parsing color scheme</div>;
        }
      },
    },
    {
      title: "Action",
      width: "30%",
      key: "action",
      className: "hiddenTblHeaderText",
      align: "center",
      render: (record: any) => (
        <Space size="middle">
          {/* <EyeOutlined
            onClick={() =>
              handleViewCssCode(record.project_id, record.thread_id)
            }
            style={{ cursor: "pointer" }}
          /> */}
         { isDownloadPossible===record.project_id && isDownloadPossible!==-1 ?
         <Spin indicator={<LoadingOutlined spin />} size="small" />:
          <DownloadOutlined onClick={()=>handleZipDownload(record.project_id, record.thread_id)}/>}
          <DeleteFilled onClick={() => handleDelete(record.project_id)} className="deleteIconHover" />
          <div
                className="tab"
                onClick={() => handleGenerateFeCode(record.project_id, record.thread_id)}
              >
                Generate FE Code
              </div>
          
        </Space>
      ),
    },
  ];

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    action: '',
    accept: '.pdf, .docx, .doc, .txt',
    showUploadList:false,
    customRequest: ({ file }: { file: any }) => {
      handleFileUpload(file);
    }
    
  };

  useEffect(() => {
    setActivePageId(0)
    getProjectList();
  }, []);
  useEffect(() => {
    isDownloadPossible === -1 &&
    openNotificationWhileDownloadError();
  }, [isDownloadPossible]);

  const paginationConfig = {
    current: currentPage,
    pageSize,
    total: resData.length,
    onChange: handlePageChange,
  };
  const openNotificationWhileDownloadError = () => {
    api['warning']({
      message: 'Operation Error',
      description:
        'You need to generate the JavaScript and CSS code as a single file first. Then you can proceed ',
        duration:5,
    });
  };

  
  return (
    <div className="container">
    {contextHolder}
      <h2>All Requirements</h2>
      <div className="fldWrap">
        <label>Search Project</label>
        <Input />
      </div>
      {/* <Upload {...props}  disabled={uploadFileName?true:false}>
      <Button
        type="primary"
        className="newProjectButton"
        // onClick={() => navigation("/MockupGenerator/requirement")}
        icon={uploadFileName?<Spin indicator={<LoadingOutlined spin className="uploadSpin" />} size="small"/>:<UploadOutlined />}
      >
       {uploadFileName?uploadFileName:<>Add Requirement</>}
      </Button>
      </Upload> */}
      <Button type="primary" className="newProjectButton" onClick={()=>setIsRightDrawerOpen(true)}>Add Project</Button>

      <div className="projectTable">
        <Table
          columns={projectColumns}
          dataSource={resData.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )}
          //dataSource={resData}
          pagination={false}
          bordered
        />
      </div>
      <Pagination
        {...paginationConfig}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
    
      <RightDrawer isOpen={isRightDrawerOpen} placement={rightDrawerPlacement} setOpen={setIsRightDrawerOpen}/>
    </div>
  );
};
export default MockupGenerator;
