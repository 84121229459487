import React, { useEffect, useState } from 'react';
import { Tree, Skeleton } from 'antd';
import type { DataNode } from 'antd/es/tree';
import DirectoryTree from 'antd/es/tree/DirectoryTree';
import mockupGeneratorService from '../../../services/mockupGenerator.service';
import { MockupStore } from '../../store/Mockup.store';
import { useParams } from 'react-router-dom';

interface FileManagementProps {
  onSelect: (selectedKeys: string[]) => void;
  visibleFileKey?: string;
  generateFeCodeWithFrameWork: (filePath: string, pageTitle: string) => void;
}

const FileManagement: React.FC<FileManagementProps> = ({ onSelect, visibleFileKey, generateFeCodeWithFrameWork }) => {
  const { setCgr_FeCode, cgr_feCode, setIsStreaming, updateHighlightedLines } = MockupStore((state: any) => state);
  const [treeView, setTreeView] = useState<DataNode[]>([]);
  const { projectId, threadId, framework } = useParams();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);  // Add loading state

  const parseTreeData = (node: any): DataNode => {
    return {
      title: <span className="truncateFileName">{node.title}</span>,
      key: node.key,
      isLeaf: node.type === 'file',
      children: node.children ? node.children.map(parseTreeData) : [],
    };
  };

  const getFileStructure = async () => {
    setLoading(true);  // Show skeleton when API call starts
    try {
      const payload = {
        thread_id: threadId,
        project_id: projectId,
        tree_view_for: "frontend",
      };
      const response = await mockupGeneratorService.cgr_getTreeView(payload);

      if (response.data.project_data.frontend_tree_view) {
        const treeData = response.data.project_data.frontend_tree_view;
        const parsedTreeData = [parseTreeData(treeData)];
        setTreeView(parsedTreeData);
      } else {
        console.error("No tree view data available");
      }
    } catch (error) {
      console.error("Error fetching file structure:", error);
    } finally {
      setLoading(false);  // Hide skeleton once API call is done
    }
  };

  useEffect(() => {
    getFileStructure();
  }, []);

  const handleSelect = async (selectedKeys: React.Key[], info: any) => {
    const { node } = info;
    if (node.isLeaf) {
      console.log('Selected file:', node.title);
      console.log('Selected file path:', node.key);

      const existingCode = cgr_feCode.find((file: any) => file.fileName ===  node.title.props.children);
      if (existingCode) {
        onSelect([node.key]);
        return;
      }

      try {
        const payload = {
          project_id: projectId,
          thread_id: threadId,
          tree_view_for: "frontend",
          page_title: node.title.props.children,
        };

        const response = await mockupGeneratorService.cgr_extractPath(payload);
        cgr_feCode.push({ fileName:  node.title.props.children, code: "", key: node.key, filePath: response.data });
        setCgr_FeCode(cgr_feCode);
        await generateFeCodeWithFrameWork(response.data,  node.title.props.children);
      } catch (error) {
        console.error("Error extracting file path:", error);
      }
    }
  };

  useEffect(() => {
    if (visibleFileKey) {
      setSelectedKeys([visibleFileKey]);
    }
  }, [visibleFileKey]);

  return (
    <aside className="cgr_fileStructure">
      {loading ? (
        <div className="skeleton-container">
          <Skeleton active paragraph={{ rows: 30 }} /> 
        </div>
      ) : (
        <DirectoryTree
          multiple
          showLine
          defaultExpandAll
          treeData={treeView}
          onSelect={handleSelect}
          selectedKeys={selectedKeys}
        />
      )}
    </aside>
  );
};

export default FileManagement;
