import { SendOutlined, SyncOutlined } from '@ant-design/icons';
import { Input, Popover } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { MockupStore } from '../../store/Mockup.store';

interface CodePreviewCardProps {
  code: string;
  title: string;
  filePath:string;
  generateFeCodeWithFrameWork:(filePath: string, pageTitle: string)=>void 
  setIsPopoverEnaballed: (key: string) => void;
  isPopoverEnaballed: string;
  key:string

}

const CodePreviewCard: React.FC<CodePreviewCardProps> = ({ code, title,generateFeCodeWithFrameWork,filePath,setIsPopoverEnaballed,isPopoverEnaballed,key }) => {
  const outputRef = useRef<any>(null);
  const {userPromptValue,setUserPromptValue} = MockupStore((state: any) => state);

  //const[userPromptValue,setUserPromptValue]=useState("");


  useEffect(() => {
    if (outputRef.current) {
      const allChildren = outputRef.current.children;
      const lastChild = allChildren[allChildren.length - 1]; 

      if (lastChild) {
        (lastChild as HTMLElement).style.transition = 'all 1s ease-in-out';
        (lastChild as HTMLElement).style.backgroundColor = '#EBCBFB'; 
        (lastChild as HTMLElement).style.opacity = '1'; 
        (lastChild as HTMLElement).style.transform = 'scale(1.05)'; 

        // Remove the effect after 1 second with animation
        setTimeout(() => {
          (lastChild as HTMLElement).style.backgroundColor = 'transparent';
          (lastChild as HTMLElement).style.opacity = '1'; 
          (lastChild as HTMLElement).style.transform = 'scale(1)'; 
        }, 500);
      }
    }
  }, [code]);

  return (
    <div className="cgr_card">
      <h3 className="cgr_title">{title}</h3>
      <Popover
          open={isPopoverEnaballed===title}
          trigger="click"
          placement="left"
          content={
            <Input
              placeholder="Write a prompt"
              value={userPromptValue}
              size="large"
              suffix={
                <SendOutlined
                  onClick={()=>
                    userPromptValue ? generateFeCodeWithFrameWork(filePath,title) 
                    : undefined
                  }
                />
              }
              onChange={(e) => setUserPromptValue(e.target.value)}
            />
          }
        >
          <SyncOutlined
            //spin={requirementApiStatus}
            onClick={() => setIsPopoverEnaballed(title)}
          />
        </Popover>
      <div className="cgr_codeLine" ref={outputRef}>
        <ReactMarkdown>{code}</ReactMarkdown>
      </div>
    </div>
  );
};

export default CodePreviewCard;
