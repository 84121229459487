import React, { useState } from "react";
import { Form, Input, Upload, Button, UploadFile, UploadProps, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import tinycolor from "tinycolor2";
import ImgCrop from "antd-img-crop";
import mockupGeneratorService from "../../services/mockupGenerator.service";
import { useNavigate } from "react-router-dom";
import { MockupStore } from "../store/Mockup.store";

interface ITemplateAdditionalFormProps {
  selectedTemplateId: string | null;
}

const colorType = [
  { heading: "Primary Color", id: 0, colorName: "#dc7979" },
  { heading: "Secondary Color", id: 1, colorName: "#e52a2a" },
  { heading: "Main text Color", id: 2, colorName: "#000000" },
  { heading: "Table Header Color", id: 3, colorName: "#8ee1a3" },
  { heading: "Table Header Text Color", id: 4, colorName: "#919191" },
  { heading: "Main Background Color", id: 5, colorName: "#28fb4b" },
  { heading: "Table Header Background Color", id: 6, colorName: "#d408b8" },
];

const TemplateAdditionalForm: React.FC<ITemplateAdditionalFormProps> = (selectedTemplateId) => {
  const [form] = Form.useForm();
  const [colors, setColors] = useState(colorType.map(({ id, colorName, heading }) => ({
    id,
    colorName,
    heading,
    colorValue: tinycolor(colorName).isValid() ? tinycolor(colorName).toHexString() : "#beb6b6",
  })));
  const { setProjectPageLists } = MockupStore((state: any) => state);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [pageListApiStatus, setPageListApiStatus] = useState(false);
  const navigation = useNavigate();

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const colorValue = e.target.value;
    const tinyColor = tinycolor(colorValue);
    const colorName = tinyColor.isValid() ? tinyColor.toHexString() : "";

    setColors((prevColors) =>
      prevColors.map((color) =>
        color.id === id ? { ...color, colorValue, colorName } : color
      )
    );
  };

  const handleUploadLogo: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    form.setFieldsValue({ logo: newFileList });
  };

  const handleColorNameChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const colorName = e.target.value;
    const tinyColor = tinycolor(colorName);
    const colorValue = tinyColor.isValid() ? tinyColor.toHexString() : "#beb6b6";

    setColors((prevColors) =>
      prevColors.map((color) =>
        color.id === id ? { ...color, colorName, colorValue } : color
      )
    );
  };

  const onPreview = async (file: any) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleSubmit = async (values: any) => {
    setPageListApiStatus(true);
    try {
      const formData = new FormData();
      formData.append("file", values.requirementDocument[0].originFileObj);
      formData.append("image", values.logo[0].originFileObj);

      const requestObject = {
        input_text: "",
        template_id: selectedTemplateId.selectedTemplateId,
        project_name: values.projectName,
        // frontend_stack: values.frontendStack,
        // backend_stack: values.backendStack,
        frontend_framework:"react",
        frontend_language:"typeScript",
        project_color_scheme: {
          primary_color: colors[0].colorName,
          secondary_color: colors[1].colorName,
          main_text_color: colors[2].colorName,
          table_header_color: colors[3].colorName,
          main_background_color: colors[5].colorName,
          table_header_background_color: colors[6].colorName,
          table_header_text_color: colors[4].colorName,
        },
      };

      formData.append("request_object", JSON.stringify(requestObject));
      const response: any = await mockupGeneratorService.generateSection(formData);

      if (response.data.project_id) {
        setProjectPageLists(response.data);
        navigation(`/MockupGenerator/requirement/${response.data.project_id}/${response.data.thread_id}`);
        setPageListApiStatus(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="formContainer">
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="templateForm">
        <Form.Item label="Project Name" name="projectName" rules={[{ required: true, message: "Please enter the project name" }]}>
          <Input placeholder="Enter project name" />
        </Form.Item>

        <Form.Item label="Frontend Stack" name="frontendStack" rules={[{ required: true, message: "Please select the frontend stack" }]}>
          <Select placeholder="Select Frontend Stack">
            <Select.Option value="react-typescript">React with TypeScript</Select.Option>
            <Select.Option value="vue-js">Vue.js</Select.Option>
            <Select.Option value="next-js">Next.js</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Backend Stack" name="backendStack" rules={[{ required: true, message: "Please select the backend stack" }]}>
          <Select placeholder="Select Backend Stack">
            <Select.Option value="python-flask">Python with Flask</Select.Option>
            <Select.Option value="nest-js">Nest.js</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Color Scheme" required>
          {colors.map((item) => (
            <div key={item.id}>
              <h6>{item?.heading}</h6>
              <div className="colorLists">
                <Input type="color" className="colorPickerInput" value={item.colorValue} onChange={(e) => handleColorChange(e, item.id)} />
                <Input type="text" value={item.colorName} onChange={(e) => handleColorNameChange(e, item.id)} placeholder="Enter Color Name" />
              </div>
            </div>
          ))}
        </Form.Item>

        <Form.Item label="Upload Logo" name="logo" valuePropName="fileList" getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)} rules={[{ required: true, message: "Please upload the project logo" }]}>
          <ImgCrop rotationSlider beforeCrop={(file) => { setFileList([file]); return false; }}>
            <Upload name="logo" onPreview={onPreview} accept="image/*" listType="picture-card" fileList={fileList} onChange={handleUploadLogo} maxCount={1} beforeUpload={() => false}>
              {fileList.length >= 1 ? null : "Upload logo"}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item label="Upload Requirement Document" name="requirementDocument" valuePropName="fileList" getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)} rules={[{ required: true, message: "Please upload the requirement document" }]}>
          <Upload name="requirementDocument" beforeUpload={() => false} accept=".pdf,.doc,.docx" maxCount={1}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={pageListApiStatus}>Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TemplateAdditionalForm;
 