import React, { useRef, useState } from 'react';
import FeMainContainer, { FeMainContainerRef } from './FeMainContainer';
import FileManagement from './FileManagement';
import { useParams } from 'react-router-dom';
import mockupGeneratorService from '../../../services/mockupGenerator.service';
import { MockupStore } from '../../store/Mockup.store';

const CgrMainWrapper: React.FC = () => {
  const mainContainerRef = useRef<FeMainContainerRef>(null);
  const [visibleFileKey, setVisibleFileKey] = useState<string | undefined>();
  const { projectId, threadId, framework } = useParams();
  const { setCgr_FeCode, cgr_feCode, setIsStreaming, updateHighlightedLines,setUserPromptValue,userPromptValue } = MockupStore((state: any) => state);
  const [isPopoverEnaballed, setIsPopoverEnaballed] = useState("");


  const handleFileSelect = (selectedKeys: string[]) => {
    if (selectedKeys.length > 0) {
      console.log(selectedKeys)
      const fileKey = selectedKeys[0] as string;
      mainContainerRef.current?.scrollToFile(fileKey);
    }
  };

  const handleScrollToFile = (fileKey: string) => {
    console.log('Scrolling to file:', fileKey);
    setVisibleFileKey(fileKey); 
  };

 const generateFeCodeWithFrameWork = async (filePath: string, pageTitle: string) => {
  setIsPopoverEnaballed("")
    try {
      setIsStreaming(true);
      let currentLine = 0;
      const payload = {
        page_path: filePath,
        page_title: pageTitle,
        frontend_page_id: 0,
        thread_id: threadId,
        project_id: projectId,
        language_name: "Typescript",
        frontend_framework: "React",
        user_input:userPromptValue
      };

      let codeChunks = "";
      const response: any = await mockupGeneratorService.generateFeCode(payload);
      const reader: ReadableStreamDefaultReader<Uint8Array> | undefined = response.body.getReader();

      while (true) {
        const result: any = await reader?.read();
        if (result) {
          const { done, value } = result;
          if (done) {
            break;
          }

          codeChunks += new TextDecoder().decode(value);

          const updatedCode = cgr_feCode.map((e: any) => {
            if (e.fileName === pageTitle) {
              return { ...e, code: codeChunks };
            }
            return e;
          });

          updateHighlightedLines(currentLine);
          currentLine++;
          setCgr_FeCode(updatedCode);
        }
      }
    } catch (error) {
      console.error("Error generating FE code:", error);
    } finally {
      setIsStreaming(false);
      setUserPromptValue("")
    }
  };

  return (
    <div className="cgr_colabClone">
      <FileManagement onSelect={handleFileSelect} generateFeCodeWithFrameWork={generateFeCodeWithFrameWork} visibleFileKey={visibleFileKey} />
      <FeMainContainer ref={mainContainerRef} onScrollToFile={handleScrollToFile} generateFeCodeWithFrameWork={generateFeCodeWithFrameWork} setIsPopoverEnaballed={setIsPopoverEnaballed} isPopoverEnaballed={isPopoverEnaballed}/>
    </div>
  );
};

export default CgrMainWrapper;
