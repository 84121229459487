import axios from 'axios';
import Cookies from 'js-cookie';
import { refreshToken } from '../components/login/useAuthenticate';
import { RoutesEnum } from '../components/models/enums/apiRoutes';

class MockupGeneratorService {
  constructor() {
    // Setting up Axios interceptors
    axios.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        
        // Check if the error is due to an expired token
        if (error.response.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;

          // Refresh the token
          const newToken:any = await refreshToken();

          if (newToken) {
            // Update the token in Cookies
            Cookies.set('wiaUser', newToken);

            // Update the token in the headers of the original request
            originalRequest.headers['Authorization'] = newToken;

            // Retry the original request
            return axios(originalRequest);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private getAuthHeaders() {
    return {
      'Authorization': Cookies.get('wiaUser')
    };
  }

  public generateSection = async (generateSectionPayload: any) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GENERATE_ALL_SECTION}`,
      generateSectionPayload,
      { headers: headers }
    );
  };

  public savePageList = async (savePageListPayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.SAVE_PAGE_LIST}`,
      savePageListPayload,
      { headers: headers }
    );
  };

  public getProjectList = async () => {
    const headers = this.getAuthHeaders();
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_PROJECT_LIST}`,
      null,
      { headers: headers }
    );
  };

  public generateRd = async (generateRdPayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await fetch(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_PAGE_WISE_RD}`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(generateRdPayload)
      }
    );
  };

  public generateCode = async (generateCodePayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await fetch(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_PAGE_WISE_CODE}`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(generateCodePayload)
      }
    );
  };

  public updateProjectPageDetails = async (updateProjectPageDetailsPayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.UPDATE_PROJECT_PAGE_DETAILS}`,
      updateProjectPageDetailsPayload,
      { headers: headers }
    );
  };

  public updateUserStory = async (updateUserStoryPayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.UPDATE_USER_STORY}`,
      updateUserStoryPayload,
      { headers: headers }
    );
  };

  public deleteProject = async (deleteProjectPayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.DELETE_PROJECT_FILE}`,
      deleteProjectPayload,
      { headers: headers }
    );
  };

  public deleteProjectPage = async (deleteProjectPagePayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.DELETE_PROJECT_PAGE}`,
      deleteProjectPagePayload,
      { headers: headers }
    );
  };

  public getProjectDetails = async (getProjectDetailsPayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_PROJECT_DETAILS}`,
      getProjectDetailsPayload,
      { headers: headers }
    );
  };
  public deleteAllCode = async (deleteAllCodePayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.DELETE_ALL_CODE_FROM_PROJECT}`,
      deleteAllCodePayload,
      { headers: headers }
    );
  };

  public getUserStoryDetails = async (getUserStoryDetailsPayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_USER_STORY_DETAILS}`,
      getUserStoryDetailsPayload,
      { headers: headers }
    );
  };

  public generateUserStory = async (generateUserStoryPayload: any) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GENERATE_USER_STORY}`,
      generateUserStoryPayload,
      { headers: headers }
    );
  };

  public deleteUserStory = async (deleteUserStoryPayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.DELETE_USER_STORY}`,
      deleteUserStoryPayload,
      { headers: headers }
    );
  };

  public updateProjectDetails = async (updateProjectDetailsPayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.UPDATE_PROJECT_DETAILS}`,
      updateProjectDetailsPayload,
      { headers: headers }
    );
  };
  
  public getLogo = async (getLogoPayload: any) => {
    const headers = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_LOGO_URL}`,
      getLogoPayload,
      { headers: headers }
    );
  };

  public generateAcceptance = async (generateAcceptancePayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await fetch(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GENERATE_ACCEPTANCE_CRITERIA}`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(generateAcceptancePayload)
      }
    );
  };
  
  public generateBeCode = async (generateBeCodePayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await fetch(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GENRATE_PAGEWISE_BACKEND_CODE}`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(generateBeCodePayload)
      }
    );
  };
  public generateFeCode = async (generateFeCodePayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await fetch(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GENRATE_PAGEWISE_FRONTEND_CODE}`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(generateFeCodePayload)
      }
    );
  };

  public generateTestCases = async (generateTestCasesPayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await fetch(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GENERATE_TEST_CASES}`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(generateTestCasesPayload)
      }
    );
  };

  public generateSingleCssFile = async (generateSingleCssFilePayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await fetch(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GENERATE_TOTAL_CSS}`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(generateSingleCssFilePayload)
      }
    );
  };

  public generateSingleJsFile = async (generateSingleJsFilePayload: any) => {
    const headers:any = {
      'Content-Type': 'application/json',
      ...this.getAuthHeaders(),
    };
    return await fetch(
      `${process.env.REACT_APP_API_URL}${RoutesEnum.GENERATE_TOTAL_JS}`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(generateSingleJsFilePayload)
      }
    );
  };


//code generator

public cgr_generateCode = async (cgr_generateCode: any) => {
  const headers:any = {
    'Content-Type': 'application/json',
    ...this.getAuthHeaders(),
  };
  return await fetch(
    `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_PAGE_WISE_CODE}`,
    {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(cgr_generateCode)
    }
  );
};

public cgr_getTreeView = async (cgr_getTreeiewPayload: any) => {
  const headers = {
    'Content-Type': 'application/json',
    ...this.getAuthHeaders(),
  };
  return await axios.post(
    `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_TREE_VIEW_BY_FRAMEWORK}`,
    cgr_getTreeiewPayload,
    { headers: headers }
  );
};

public cgr_extractPath = async (cgr_extractPathPayload: any) => {
  const headers = {
    'Content-Type': 'application/json',
    ...this.getAuthHeaders(),
  };
  return await axios.post(
    `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_EXTRACT_PATH_FROM_TREEVIEW}`,
    cgr_extractPathPayload,
    { headers: headers }
  );
};

public cgr_generateFeCodeWithFramework = async (cgr_generateFeCodeWithFramework: any) => {
  const headers:any = {
    'Content-Type': 'application/json',
    ...this.getAuthHeaders(),
  };
  return await fetch(
    `${process.env.REACT_APP_API_URL}${RoutesEnum.GENERATE_FRONTEND_CODE_WITH_FRAMEWORK}`,
    {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(cgr_generateFeCodeWithFramework)
    }
  );
};


}
const mockupGeneratorService = new MockupGeneratorService();
export default mockupGeneratorService;
